/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { PostInstance } from "../../models/post-instance";
import PrimaryTextBox from "../../../../../components/common/PrimaryTextBox";
import AttachmentSlot from "../../models/attachment-slot";
import { ThreadsContentInstance } from "../../../../../models/entities/post";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { v4 as uuidv4 } from "uuid";
import ThreadAttachments from "./ThreadAttachments";
import eventBusService from "../../../../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../../../../services/application/event-bus/event-bus-events";

interface ThreadsFormProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
  children?: React.ReactNode | (({ primaryForm, secondaryForm }) => void);
}

interface ThreadsFormInput {
  caption: string;
}

const schema = yup
  .object({
    caption: yup.string(),
  })
  .required();

export default function ThreadsForm({
  postInstance,
  attachments,
  onPostInstanceChanged,
  children,
}: ThreadsFormProps) {
  const [currentInstance, setCurrentInstance] =
    useState<ThreadsContentInstance>(
      postInstance.postConfig.threads.contentOptions.instances[0]
    );

  const threadsConfig = postInstance.postConfig.threads;

  const form = useForm<ThreadsFormInput>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      caption: threadsConfig.contentOptions.instances?.[0]?.message,
    },
  });

  const prevAttachmentsRef = useRef();

  useEffect(() => {
    const prevValue = (prevAttachmentsRef.current as AttachmentSlot[]) ?? [];

    // Filter out selected attachments which are not in the current attachment list
    threadsConfig.contentOptions.instances.forEach((instance) => {
      instance.attachmentIds = instance.attachmentIds.filter((id) =>
        attachments.map((a) => a.id).includes(id)
      );
    });

    // Find newly added attachments and assign them to the current instance
    const newAttachments = attachments.filter(
      (a) => !prevValue.map((x) => x.id).includes(a.id)
    );

    if (newAttachments.length > 0) {
      const updatedContentInstances = [
        ...postInstance.postConfig.threads.contentOptions.instances,
      ];

      const index = currentInstance.index;

      const updatedContentInstance: ThreadsContentInstance = {
        ...currentInstance,
        attachmentIds: currentInstance.attachmentIds.concat(
          newAttachments.map((a) => a.id)
        ),
      };

      if (index !== -1) {
        updatedContentInstances[index] = updatedContentInstance;
      }

      const updatedPostInstance: PostInstance = {
        ...postInstance,
        postConfig: {
          ...postInstance.postConfig,
          threads: {
            contentOptions: {
              ...threadsConfig.contentOptions,
              instances: updatedContentInstances,
            },
          },
        },
      };

      onPostInstanceChanged(updatedPostInstance, true);
    }

    (prevAttachmentsRef as any).current = attachments;
  }, [
    attachments,
    currentInstance,
    onPostInstanceChanged,
    postInstance,
    threadsConfig.contentOptions,
  ]);

  // Receive AI content from AI service and insert it into the textbox
  useEffect(() => {
    const insertAiContent = (content) => {
      form.setValue("caption", content);

      if (currentInstance) {
        onCaptionChanged(currentInstance, content);
      }
    };

    eventBusService.on(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);

    return () => {
      eventBusService.remove(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);
    };
  });

  useEffect(() => {
    const setSystemPostType = (contentInstance: ThreadsContentInstance) => {
      let systemPostType = contentInstance.systemPostType;

      const initialSystemPostType = systemPostType;
      const attachmentIds = contentInstance.attachmentIds;
      const instanceAttachments = attachments.filter((a) =>
        attachmentIds.includes(a.id)
      );

      if (instanceAttachments.length == 0) {
        systemPostType = "TextPost";
      } else if (instanceAttachments.length == 1) {
        systemPostType = instanceAttachments[0].isPhoto
          ? "PhotoPost"
          : "VideoPost";
      } else if (instanceAttachments.length > 1) {
        systemPostType = "CarouselPost";
      }

      if (systemPostType != initialSystemPostType) {
        const updatedInstance: ThreadsContentInstance = {
          ...contentInstance,
          systemPostType: systemPostType,
        };

        const updatedContentInstances = [
          ...postInstance.postConfig.threads.contentOptions.instances,
        ];
        const index = updatedContentInstances.findIndex(
          (i) => i.id === contentInstance.id
        );

        if (index !== -1) {
          updatedContentInstances[index] = updatedInstance;
        }

        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            threads: {
              contentOptions: {
                ...threadsConfig.contentOptions,
                instances: updatedContentInstances,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    threadsConfig.contentOptions.instances.forEach((instance) => {
      setSystemPostType(instance);
    });
  }, [threadsConfig.contentOptions, attachments, postInstance]);

  useEffect(() => {
    form.setValue("caption", currentInstance.message);
  }, [currentInstance]);

  const onCaptionChanged = useCallback(
    (instance: ThreadsContentInstance, text: string) => {
      const updatedContentInstances = [
        ...postInstance.postConfig.threads.contentOptions.instances,
      ];

      const index = instance.index;

      const updatedContentInstance: ThreadsContentInstance = {
        ...instance,
        message: text,
      };

      if (index !== -1) {
        updatedContentInstances[index] = updatedContentInstance;
      }

      const updatedPostInstance: PostInstance = {
        ...postInstance,
        title: index == 0 ? text : postInstance.title,
        postConfig: {
          ...postInstance.postConfig,
          threads: {
            contentOptions: {
              ...threadsConfig.contentOptions,
              instances: updatedContentInstances,
            },
          },
        },
      };

      onPostInstanceChanged(updatedPostInstance, true);
    },
    [onPostInstanceChanged, postInstance, threadsConfig.contentOptions]
  );

  const deleteThread = (contentInstance: ThreadsContentInstance) => {
    let updatedContentInstances =
      postInstance.postConfig.threads.contentOptions.instances.filter(
        (x) => x.id !== contentInstance.id
      );

    updatedContentInstances = updatedContentInstances.map((instance, i) => ({
      ...instance,
      index: i,
    }));

    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        threads: {
          contentOptions: {
            ...threadsConfig.contentOptions,
            instances: updatedContentInstances,
          },
        },
      },
    };

    const newCurrentIndex = Math.min(
      contentInstance.index,
      updatedContentInstances.length - 1
    );
    const newCurrentInstance = updatedContentInstances.find(
      (x) => x.index === newCurrentIndex
    );

    onPostInstanceChanged(updatedPostInstance, true);
    setCurrentInstance(newCurrentInstance);
  };

  const addThread = (contentInstance: ThreadsContentInstance) => {
    let updatedContentInstances = [
      ...postInstance.postConfig.threads.contentOptions.instances,
    ];

    const index = updatedContentInstances.findIndex(
      (x) => x.id === contentInstance.id
    );
    if (index === -1) {
      return;
    }

    // const newId =
    //   updatedContentInstances.length > 0
    //     ? updatedContentInstances[updatedContentInstances.length - 1].id + 1
    //     : 1;

    let newContentInstance: ThreadsContentInstance = {
      id: uuidv4().substring(0, 8),
      index: -1,
      systemPostType: "TextPost",
      message: null,
      link: null,
      attachmentIds: [],
    };

    updatedContentInstances.splice(index + 1, 0, newContentInstance);
    updatedContentInstances = updatedContentInstances.map((instance, i) => ({
      ...instance,
      index: i,
    }));

    // updatedContentInstances.push(newContentInstance);

    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        threads: {
          contentOptions: {
            ...threadsConfig.contentOptions,
            instances: updatedContentInstances,
          },
        },
      },
    };

    newContentInstance =
      updatedPostInstance.postConfig.threads.contentOptions.instances.find(
        (x) => x.id === newContentInstance.id
      );

    setCurrentInstance(newContentInstance);
    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onToggleAttachment = (
    contentInstance: ThreadsContentInstance,
    attachmentSlot: AttachmentSlot
  ) => {
    const updatedContentInstances = [
      ...postInstance.postConfig.threads.contentOptions.instances,
    ];

    const index = contentInstance.index;

    const updatedContentInstance: ThreadsContentInstance = {
      ...contentInstance,
      attachmentIds: contentInstance.attachmentIds.includes(attachmentSlot.id)
        ? contentInstance.attachmentIds.filter((x) => x !== attachmentSlot.id)
        : [...contentInstance.attachmentIds, attachmentSlot.id],
    };

    if (index !== -1) {
      updatedContentInstances[index] = updatedContentInstance;
    }

    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        threads: {
          contentOptions: {
            ...threadsConfig.contentOptions,
            instances: updatedContentInstances,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const primaryForm = (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row -mt-2">
          <fieldset className="w-full h-6 mt-3 block"></fieldset>
        </div>

        {postInstance.postConfig.threads.contentOptions.instances.map(
          (instance) => (
            <Fragment key={instance.id}>
              {currentInstance.id === instance.id && (
                <div className="mb-2">
                  {/* Caption */}
                  <div>
                    <PrimaryTextBox
                      onTextChanged={(value) =>
                        onCaptionChanged(instance, value)
                      }
                      textAreaOptions={{
                        hideLabel: true,
                        autoFocus: true,
                        maxLength: 500,
                        className: "h-[124px]",
                        name: "caption",
                        label: "Caption",
                        formHook: form,
                        placeholder: "Your post caption",
                      }}
                    />
                  </div>
                  <div className="flex items-center mt-2 gap-4">
                    <div className="flex-1">
                      {postInstance.postConfig.threads.contentOptions.instances
                        .length > 1 && (
                        <ThreadAttachments
                          threadInstance={instance}
                          attachmentSlots={attachments}
                          onToggleAttachment={(attachmentSlot) =>
                            onToggleAttachment(instance, attachmentSlot)
                          }
                        />
                      )}
                    </div>
                    <div className="flex justify-end gap-2 items-center">
                      {postInstance.postConfig.threads.contentOptions.instances
                        .length > 1 && (
                        <button
                          onClick={() => deleteThread(instance)}
                          type="button"
                          className="inline-flex items-center gap-x-1.5  rounded bg-white px-2 py-1 text-xs font-semibold text-gray-400 hover:text-gray-600"
                        >
                          <TrashIcon
                            aria-hidden="true"
                            className="-mr-0.5 h-5 w-5"
                          />
                        </button>
                      )}
                      <button
                        onClick={() => addThread(instance)}
                        type="button"
                        className="inline-flex items-center gap-x-1.5 rounded bg-white px-2 py-1 text-xs font-medium text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Add Thread
                        <PlusCircleIcon
                          aria-hidden="true"
                          className="-mr-0.5 h-5 w-5"
                        />
                      </button>

                      {/* <Button
                      text="Add Thread"
                      onClick={addThread}
                      variant="text"
                      color="gray"
                      disabled={false}
                      icon={}
                    /> */}
                    </div>
                  </div>
                </div>
              )}
              {currentInstance.id !== instance.id && (
                <div
                  className="flex flex-col border rounded p-2 cursor-pointer gap-2"
                  onClick={() => setCurrentInstance(instance)}
                >
                  {!instance.message &&
                  !instance.link &&
                  !instance.attachmentIds.length ? (
                    <div className="text-gray-400 text-sm">Add a post...</div>
                  ) : (
                    <>
                      <div className="text-gray-400 text-sm">
                        {instance.message ?? instance.link}
                      </div>
                      {instance.attachmentIds.length > 0 && (
                        <div className="flex flex-wrap gap-1">
                          {instance.attachmentIds.map((attachmentId) => {
                            const attachment = attachments.find(
                              (a) => a.id === attachmentId
                            );

                            return (
                              attachment && (
                                <div key={attachment.id}>
                                  <img
                                    src={attachment.thumbnailPreview?.url ?? ""}
                                    className="w-6 h-6 object-cover rounded opacity-50"
                                  />
                                </div>
                              )
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </Fragment>
          )
        )}
      </div>
    </>
  );

  const secondaryForm = (
    <>
      {/* <div className="mt-6 border rounded-md px-4 py-2">
        <div className="leading-8 font-medium text-md text-gray-700">More Options</div>
        <div className="mt-2">
          <div className="flex justify-start items-center gap-4 relative">
            <div className="text-gray-700 font-semibold text-xs">Super followers</div>
          </div>
        </div>
      </div> */}
    </>
  );

  return (
    <form>
      <>
        {typeof children === "function"
          ? children({ primaryForm, secondaryForm })
          : children}{" "}
      </>
    </form>
  );
}
