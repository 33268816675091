import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import RootLayout from "../layouts/RootLayout";
import OAuthLayout from "../layouts/OAuthLayout";
import MainLayout from "../layouts/MainLayout";
import Analytics from "../modules/analytics";
import Engagement from "../modules/engagement";
import LinkInBio from "../modules/link-in-bio";
import Settings from "../modules/settings";
import CreateSocialSet from "../modules/social-sets/create/CreateSocialSet";
import EditSocialSet from "../modules/social-sets/edit/EditSocialSet";
import ViewSocialSet from "../modules/social-sets/view/ViewSocialSet";
import ListSocialSets from "../modules/social-sets/list/ListSocialSets";
import NotificationsSettings from "../modules/settings/notifications/NotificationsSettings";
import PlanSettings from "../modules/settings/plan/PlanSettings";
import BillingSettings from "../modules/settings/billing/BillingSettings";
import BootstrapperLayout from "../layouts/BootstrapperLayout";
import AuthenticatorLayout from "../layouts/AuthenticatorLayout";
import GenericError from "../components/errors/GenericError";
import Invite from "../modules/invite/Invite";
import Signup from "../modules/auth/signup/Signup";
import Users from "../modules/settings/users/list/Users";
import CreateUser from "../modules/settings/users/create/CreateUser";
import ViewUser from "../modules/settings/users/view/ViewUser";
import EditUser from "../modules/settings/users/edit/EditUser";
import Signout from "../modules/auth/signout/Signout";
import Signin from "../modules/auth/signin/Signin";
import OAuthCallback from "../modules/oauth/callback/OAuthCallback";
import OAuthRelayCallback from "../modules/oauth/callback/OAuthRelayCallback";
import SchedulerCalendar from "../modules/scheduler/calendar/Calendar";
import ScheduledPosts from "../modules/scheduler/posts/Posts";
import EditWorkspace from "../modules/settings/workspace/edit/EditWorkspace";
import WorkspaceSettings from "../modules/settings/workspace/WorkspaceSettings";
import ProfileSettings from "../modules/settings/profile/ProfileSettings";
import EditProfile from "../modules/settings/profile/edit/EditProfile";
import * as Sentry from "@sentry/react";
import SignupCoupon from "../modules/auth/signup-coupon/SignupCoupon";
import Affiliate from "../modules/settings/affiliate/Affiliate";
import Page404 from "../pages/Page404";
import ActiveSubscriptionGuard from "../guards/ActiveSubscriptionGuard";
import UsersRoot from "../modules/settings/users/UsersRoot";
import SocialSetsRoot from "../modules/social-sets/SocialSetsRoot";
import SchedulerRoot from "../modules/scheduler/SchedulerRoot";
import Channels from "../modules/social-sets/channels/Channels";
import SchedulerList from "../modules/scheduler/list/SchedulerList";
import ListQueue from "../modules/scheduler/list/queue/ListQueue";
import ListDrafts from "../modules/scheduler/list/drafts/ListDrafts";
import ListApprovals from "../modules/scheduler/list/approvals/ListApprovals";
import ListPublished from "../modules/scheduler/list/published/ListPublished";
import ChannelListSettings from "../modules/scheduler/list/settings/ChannelListSettings";
import MediaLayout from "../modules/scheduler/media/MediaLayout";
import HashtagsLayout from "../modules/scheduler/hashtags/HashtagsLayout";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: (
      <Sentry.ErrorBoundary fallback={<GenericError />} showDialog>
        <RootLayout />
      </Sentry.ErrorBoundary>
    ),
    children: [
      { path: "404", element: <Page404 /> },
      {
        path: "/",
        element: <Navigate to="/scheduler" replace />,
      },
      {
        path: "/invite",
        element: <Invite />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/login",
        element: <Signin />,
      },
      {
        path: "/sign-out",
        element: <Signout />,
      },
      {
        path: "/coupon-signup/:couponId",
        element: <SignupCoupon />,
      },
      {
        path: "/oauth/cognito-redirect",
        element: <Navigate to="/scheduler" replace />,
      },
      {
        path: "/",
        element: <AuthenticatorLayout />,
        children: [
          {
            path: "/",
            element: <BootstrapperLayout />,
            children: [
              {
                path: "oauth",
                element: <OAuthLayout />,
                children: [
                  {
                    path: "facebook/callback",
                    element: (
                      <OAuthCallback
                        channelType="Facebook"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "instagram/callback",
                    element: (
                      <OAuthCallback
                        channelType="Instagram"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "pinterest/callback",
                    element: (
                      <OAuthCallback
                        channelType="Pinterest"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "twitter/callback",
                    element: (
                      <OAuthCallback
                        channelType="Twitter"
                        callbackParams={["oauth_verifier", "oauth_token"]}
                        stateProvider="SessionStorage"
                      />
                    ),
                  },
                  {
                    path: "google/callback",
                    element: (
                      <OAuthCallback
                        channelType="YouTube"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "tiktok/callback",
                    element: (
                      <OAuthCallback
                        channelType="TikTok"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "tiktok/callback-relay",
                    element: (
                      <OAuthRelayCallback to="https://localhost:5173/oauth/tiktok/callback" />
                    ),
                  },
                  {
                    path: "threads/callback",
                    element: (
                      <OAuthCallback
                        channelType="Threads"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "linkedin/callback",
                    element: (
                      <OAuthCallback
                        channelType="LinkedIn"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                ],
              },
              {
                path: "/",
                element: <MainLayout />,
                children: [
                  {
                    path: "/",
                    element: (
                      <ActiveSubscriptionGuard>
                        <Outlet />
                      </ActiveSubscriptionGuard>
                    ),
                    children: [
                      {
                        path: "scheduler",
                        element: <SchedulerRoot />,
                        children: [
                          {
                            path: "",
                            element: <Navigate to="calendar" replace />,
                          },
                          {
                            path: "calendar",
                            element: <Outlet />,
                            children: [
                              {
                                path: "",
                                element: <Navigate to="view" replace />,
                              },
                              {
                                path: "view",
                                element: <SchedulerCalendar />,
                              },
                              {
                                path: "posts",
                                element: <ScheduledPosts />,
                              },
                              {
                                path: "media-library",
                                element: <MediaLayout />,
                              },
                              {
                                path: "hashtag-lists",
                                element: <HashtagsLayout />,
                              },
                            ],
                          },
                          {
                            path: "list",
                            element: <SchedulerList />,
                            children: [
                              {
                                path: "",
                                element: <Navigate to="queue" replace />,
                              },
                              {
                                path: "queue",
                                element: <ListQueue />,
                              },
                              {
                                path: "drafts",
                                element: <ListDrafts />,
                              },
                              {
                                path: "approvals",
                                element: <ListApprovals />,
                              },
                              {
                                path: "published",
                                element: <ListPublished />,
                              },
                              {
                                path: "settings",
                                element: <ChannelListSettings />,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: "analytics",
                        element: <Analytics />,
                      },
                      {
                        path: "engagement",
                        element: <Engagement />,
                      },
                      {
                        path: "link-in-bio",
                        element: <LinkInBio />,
                      },
                      {
                        path: "social-sets",
                        element: <SocialSetsRoot />,
                        children: [
                          {
                            path: "",
                            element: <ListSocialSets />,
                          },
                          {
                            path: ":id",
                            element: <ViewSocialSet />,
                          },
                          {
                            path: ":id/edit",
                            element: <EditSocialSet />,
                          },
                          {
                            path: "create",
                            element: <CreateSocialSet />,
                          },
                          {
                            path: ":id/channels",
                            element: <Channels />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "settings",
                    element: <Settings />,
                    children: [
                      {
                        path: "",
                        element: <Navigate to="profile" replace />,
                      },
                      {
                        path: "profile",
                        element: <ProfileSettings />,
                      },
                      {
                        path: "profile/edit",
                        element: <EditProfile />,
                      },
                      {
                        path: "workspace",
                        element: <WorkspaceSettings />,
                      },
                      {
                        path: "workspace/edit",
                        element: <EditWorkspace />,
                      },
                      {
                        path: "notifications",
                        element: <NotificationsSettings />,
                      },
                      {
                        path: "plan",
                        element: <PlanSettings />,
                      },
                      {
                        path: "billing",
                        element: <BillingSettings />,
                      },
                      {
                        path: "users",
                        element: <UsersRoot />,
                        children: [
                          {
                            path: "",
                            element: <Users />,
                          },
                          {
                            path: "invite",
                            element: <CreateUser />,
                          },
                          {
                            path: ":id",
                            element: <ViewUser />,
                          },
                          {
                            path: ":id/edit",
                            element: <EditUser />,
                          },
                        ],
                      },
                      {
                        path: "affiliate",
                        element: <Affiliate />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      // No match
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
]);

export default router;
